import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './App.css';
import logo from './logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
}));

export default function NestedGrid() {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item lg={4}>
            <img src={logo} className="App-logo" alt="logo" />
        </Grid>
        <Grid item lg={7}>
            <p className="heading">
              Scrum <b>Pro</b>
            </p>
            <p className="medtext">Step up, get noticed.</p>
            <p className="bodytext">Your solution to an editable, shareable Rugby CV, all in one space.</p>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <FormRow />
        </Grid>
      </Grid>
    </div>
  );
}