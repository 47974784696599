import React from 'react';
import './App.css';
import Landing from './Landing';
import LandingSwitch from './LandingSwitch';

export default ScrumPro; 



function ScrumPro () {
    return (
    <div className="App">
        <div className="info-card">
          <div className="cardmain">
            <Landing />
            <LandingSwitch />
          </div>
        </div>
      </div>
    );
     
}
