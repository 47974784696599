import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './App.css';

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    textAlign: "center",
  },
}));

export default function NestedGrid() {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
          <Grid item md={3}><p className="linktext"> What is Scrum<b>Pro</b>?</p></Grid>
          <Grid item md={1}><Link to="/AboutUs"><i class="material-icons pageItem">expand_more</i></Link></Grid>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <FormRow />
        </Grid>
      </Grid>
    </div>
  );
}