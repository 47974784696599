import React from 'react';
import './App.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import CircularProgress from '@material-ui/core/CircularProgress';

const accountType = [
  {
    value: 'player',
    label: 'Player',
  },
  {
    value: 'coach',
    label: 'Coach',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const Location = [
  {
    value: 'Eastern Cape',
    label: 'Eastern Cape',
  },
  {
    value: 'Free State',
    label: 'Free State',
  },
  {
    value: 'Gauteng',
    label: 'Gauteng',
  },
  {
    value: 'KwaZulu-Natal',
    label: 'KwaZulu-Natal',
  },
  {
    value: 'Limpopo',
    label: 'Limpopo',
  },
  {
    value: 'Mpumalanga',
    label: 'Mpumalanga',
  },
  {
    value: 'Northern Cape',
    label: 'Northern Cape',
  },{
    value: 'North West',
    label: 'North West',
  },
  {
    value: 'Western Cape',
    label: 'Western Cape',
  },
  {
    value: 'Namibia',
    label: 'Namibia',
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
  },
  {
    value: 'Other',
    label: 'Other',
  }
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#fff',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    color: '#fff',
    fontSize: 20,
  },
  svg: {
    color: 'white',
  },
  button: {
    backgroundColor: '#3CB5FE',
    width: '97%',
    margin: 'auto',
    marginTop: '2vh', 
    fontFamily: 'Franklin Libre, sans-serif',
  }
}));



export default function OutlinedTextFields() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    Location: '',
    accountType: '',
    message: '',
    error: '',
    isLoaded: true
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const postForm = () => {
    const { name, email, accountType, Location, message } = values;
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
    if (name == null || name === '') {
      Swal.fire({
        title: 'Warning',
        text: 'Please enter a name',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
      return;
    }
  
    if (email == null || email === '' || !email.match(emailRegex)) {
      Swal.fire({
        title: 'Warning',
        text: 'Please enter a valid email address',
        type: 'warning',
        confirmButtonText: 'Ok'
      });
      return;
    }
  
    setValues({ ...values, isLoaded: false });
  
    fetch('https://api.scanplan.co.za/v2/functions/scrumpro', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "name": name,
        "email": email,
        "location": Location,
        "occupation": accountType,
        "message": message
      })
    })
    .then(result => {
      console.log(result);
      if (result.status !== 200) {
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred. Please try agian.',
          type: 'error',
          confirmButtonText: 'Ok'
        });
        setValues({ ...values, isLoaded: true });
        return;
      }
      Swal.fire({
        title: 'Success',
        text: "Awesome, we can't wait to have you on board!",
        type: 'success',
        confirmButtonText: 'Thanks!'
      })
      setValues({ isLoaded: true });
    });
  }

  if (values.isLoaded) {
    return (
      <div class="ContactForm">
        <div class="contentcontact">
          <h2 class="marginleft">We’re kicking off soon! </h2>
          <p>Complete the form below to get your name in the queue, and you’ll receive our launch special of 3 months FREE, PLUS spot prizes like kit vouchers and so much more!</p>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              color= "#fff"
              id="outlined-name"
              label="Name"
              className={classes.textField}
              value={values.name}
              onChange={handleChange('name')}
              variant="outlined"
              fullWidth
              margin= "dense"
            />
  
            <TextField
              id="outlined-email-input"
              label="Email"
              className={classes.textField}
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange('email')}
              autoComplete="email"
              variant="outlined"
              fullWidth
              margin= "dense"
            />
  
            <TextField
              select
              label="Where are you from?"
              variant="outlined"
              fullWidth
              margin= "dense"
              className={clsx(classes.margin, classes.textField)}
              value={values.Location}
              onChange={handleChange('Location')}
              >
              {Location.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
  
            <TextField
              select
              label="Who are you?"
              variant="outlined"
              fullWidth
              margin= "dense"
              className={clsx(classes.margin, classes.textField)}
              value={values.accountType}
              onChange={handleChange('accountType')}
              >
              {accountType.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
  
            <TextField
              id="outlined-multiline-static"
              label="Extra Info"
              helperText="Tell us more about yourself, or ask us a question."
              multiline
              rows="3"
              className={classes.textField}
              value={values.message}
              onChange={handleChange('message')}
              variant="outlined"
              fullWidth
              margin= "dense"
            />
  
            <Button variant="contained" size="large" color="primary" className={classes.button} onClick={postForm}>
              Send
            </Button>
          </form>
        </div>
        <div class="footer">
          <p class="footertext">Scrum<b>Pro</b>, Powered by <i>Necta Tech</i></p>
        </div>
      </div>
    );
  } else {
    return (
      <div class="ContactForm flexCont">
        <div class="contentcontact loadingOuter">
          <div class="loadingForm">
            <CircularProgress class="loading-circle" />
          </div>
        </div>
      </div>
    );
  }  
}
