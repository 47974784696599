import React from 'react';
import './App.css';
import ContactForm from './ContactForm';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import About from './About'
import ScrumPro from './ScrumPro'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/AboutUs" component={AboutUs} />
      <Contact />
      
    </Router>
    
  );
}



const Home = () => {
  return (
  <ScrumPro />
  );
}

const AboutUs = () => {
    return (
    <About />
    );
  }

const Contact = () => {
  return(
    <div>
      <ContactForm />
    </div>
  );
}

export default App;
