import React from 'react';
import './App.css';
import InfoGrid from './InfoGrid';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import StepsGrid from './StepsGrid';
import ContactForm from "./ContactForm";

import share from './screens/share.png';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  card: {
  },
  media: {
    height: 140,
  },
  gridItem: {
    marginTop: "2rem", 
  }
});

const Contact = () => {
  return(
    <div>
      <ContactForm />
    </div>
  );
}

export default function About () { 
 const classes = useStyles();

    return (
        <div className="App2 info-card2">
        <div class="content">
          <Grid item xs={9}>  
            <p className="medtext">What is Scrum<b>Pro?</b></p>
            <InfoGrid />
            <div className="spacer"/>

            <h1 className="gridItem">How does it work?</h1>
            <StepsGrid />

            <Grid item xs ={12}>
            <p>With a nice meaty profile loaded, you’re ready to start sharing with anyone you like, anywhere in the world. Simply click on the Share link in the top right of your profile and we will generate you a shareable link, to publish anywhere you like - WhatsApp, FaceBook, email, wherever! 
            </p>
            </Grid>

            <h1 className="paragraph">With that link, people (coaches for example) can:</h1>
              <ul>
                <li>
                  <p>View all of your info in one easy to access place.</p>
                </li>

                <li>
                <p>Save your profile to refer back to at any point in time.</p>
                </li>

                <li> 
                <p>Make a note of where you are next playing to come and see for themselves</p>
                </li>
                
              </ul>
            
            <p className="paragraph"><b>The coolest thing is that you can see just how many times your link has been clicked on and shared!</b></p>
            
            <Grid item lg={12}>
              <img src={share} className="stock-image"></img>
            </Grid>

            <h1 className="paragraph">So what are you waiting for? Step out onto the field.</h1>

            <Link to="/"><i class="material-icons gridItem">expand_more</i></Link>
          </Grid>
        </div>
      </div>
    );
}
